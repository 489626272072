// @flow

import React from 'react';
import type { Node } from 'react';
import { Link, Image } from '@riseart/common';
import { Section, SectionContent, Wrapper } from '@riseart/layout';
import { GridSquare, GridSquareItem } from '@riseart/grid';
import { generateContentUrl } from 'shared_services/riseart/utils/Utils';

import { pressCls, pressLogoCls } from './Press.less';

const LOGO_LAZYLOAD_CONFIG = { height: 30 };

type Props = {
  disableHeadingTags?: boolean,
  title?: string,
  logos: Array<{
    text?: string,
    image: Object,
    link: {
      href: string,
      external: boolean,
    },
  }>,
};

/**
 * CmsModulePress
 *
 * @param {Props} props
 * @returns {Node}
 */
export const CmsModulePress = ({ title, logos, disableHeadingTags = false }: Props): Node => {
  if (!logos || !logos.length) {
    return null;
  }

  return (
    <Section className={pressCls}>
      <Wrapper>
        <SectionContent
          title={title}
          headingProps={{ tag: disableHeadingTags ? 'span' : 'h3', level: 3, align: 'center' }}
        >
          <GridSquare columnCount="7">
            {logos &&
              logos.map(({ link, image: { key, extension }, text }) => {
                const ImageComponent = (
                  <Image
                    src={generateContentUrl(key, extension)}
                    alt={text}
                    lazyload={LOGO_LAZYLOAD_CONFIG}
                  />
                );

                return (
                  <GridSquareItem key={key} className={pressLogoCls} columnCount="7">
                    {link ? (
                      <Link
                        type="light"
                        external={typeof link.external === 'boolean' ? link.external : true}
                        href={link.href}
                        title={text}
                      >
                        {ImageComponent}
                      </Link>
                    ) : (
                      ImageComponent
                    )}
                  </GridSquareItem>
                );
              })}
          </GridSquare>
        </SectionContent>
      </Wrapper>
    </Section>
  );
};
